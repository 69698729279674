import React, { useEffect, useState } from "react";
import TextEditor from "components/modules/TextEditor";
import LogoUpload from "components/modules/LogoUpload";
import styled from 'styled-components';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { PULL_ORGANIZATION, UPDATE_ORGANIZATION } from "constants/actions";
import Toggle from "components/Toggle";
import { useToasts } from "react-toast-notifications";
const SurveyCustomizations = ({ organizationId }) => {
    const dispatch = useDispatch();
    const [updatedContent, setUpdatedContent] = useState("");
    const [logoHeight, setLogoHeight] = useState();
    const [logoWidth, setLogoWidth] = useState();
    const [triggerSave, setTriggerSave] = useState(false);
    const [orgWhiteLabel, setOrgWhiteLabel] = useState(null);
    const [showSurveyLandingPage, setShowSurveyLandingPage] = useState(false);
    const { addToast } = useToasts();
    const handleEditorChange = (content) => {
        setUpdatedContent(content);
    };
    
    const { get_organization } = useSelector(
        (state) => ({
            get_organization: state.organizations,
        }), shallowEqual);
    
    useEffect(() => {
        dispatch({
            type: PULL_ORGANIZATION,
            payload: { id: Number(organizationId) },
        });
    }, []);
    
    useEffect(() => {
        if (get_organization && get_organization[organizationId]?.styling) {
            setOrgWhiteLabel(get_organization[organizationId].styling);
            // Set the landing page state if it exists in the object and is set to true.
            if (get_organization?.[organizationId]?.styling?.show_intro) {
                setShowSurveyLandingPage(true);
            }
        }
    }, [get_organization, organizationId]);
    const handleSave = (orgWhiteLabel) => {
        const height = logoHeight || orgWhiteLabel?.logo_height || 200;
        const width = logoWidth || orgWhiteLabel?.logo_width || 200;
        const updatedWhiteLabel = {
            ...orgWhiteLabel,
            logo_width: width,
            logo_height: height,
            survey_introduction: updatedContent,
            show_intro: showSurveyLandingPage,
        };
        saveWhiteLabel(updatedWhiteLabel);
    };
    const saveWhiteLabel = (data) => {
        setTriggerSave(true);
        dispatch({
            type: UPDATE_ORGANIZATION,
            payload: { id: organizationId, styling: data },
        });
        addToast("Survey landing page updated successfully.", { appearance: "success" });
    };
    return (
        <div className="survey-customizations">
            <Header>
                <h2>Customize Survey Landing Page</h2>
                <SaveButton onClick={() => handleSave(orgWhiteLabel)}>Save</SaveButton>
            </Header>
            <Section>
                <Toggle
                    label="Show landing"
                    labelDescription={"Users will see this page before taking the survey."}
                    value={showSurveyLandingPage}
                    onChange={() => setShowSurveyLandingPage(!showSurveyLandingPage)}
                />
            </Section>
            <Section className="logo-upload-section">
                <h3>Upload Organization Logo</h3>
                {get_organization && get_organization?.[organizationId] && (
                    <LogoUpload 
                        organization={get_organization[organizationId]} 
                        setHeight={setLogoHeight}
                        setWidth={setLogoWidth}
                        saveLogoCb={triggerSave}
                        setTrigger={setTriggerSave}
                        currentHeight={orgWhiteLabel?.logo_height}
                        currentWidth={orgWhiteLabel?.logo_width}
                    />
                )}
            </Section>
            <Section className="editor-section">
                <h3>Survey Introduction</h3>
                <p>Write a rich text introduction to display on the survey landing page.</p>
                <TextEditor value={orgWhiteLabel?.survey_introduction || ''} onChange={handleEditorChange} />
            </Section>
        </div>
    );
};
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
`;
const SaveButton = styled.button`
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;
const Section = styled.section`
    margin-bottom: 2rem;
`;
export default SurveyCustomizations;