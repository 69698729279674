import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Segment, Button, Icon } from "semantic-ui-react";
import { UPDATE_ORGANIZATION_LOGO, UPDATE_ORGANIZATION } from "constants/actions";

const ImageUpload = ({ organization, setHeight, setWidth, saveLogoCb, setTrigger, currentHeight, currentWidth }) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [height, updateHeight] = useState(currentHeight || 200);
    const [width, updateWidth] = useState(currentHeight || 200);

    useEffect(() => {
        if (organization?.logo) {
            setPreview(organization.logo);
        }
    }, [organization]);

    useEffect(() => {
        if (saveLogoCb && selectedFile) {
            handleSave();
        }
    }, [saveLogoCb]);

    const handleDrop = (droppedFiles) => {
        if (droppedFiles.length === 0) {
            setSelectedFile(null);
            setPreview(null);
            return;
        }
        const file = droppedFiles[0];
        const objectUrl = URL.createObjectURL(file);
        setSelectedFile(file);
        setPreview(objectUrl);
        // Cleanup URL object
        return () => URL.revokeObjectURL(objectUrl);
    };

    const handleSave = () => {
        if (!organization?.id) return;
        const actionType = selectedFile ? UPDATE_ORGANIZATION_LOGO : UPDATE_ORGANIZATION;
        const logo = selectedFile || null;
        dispatch({
            type: actionType,
            payload: { id: organization.id, logo },
        });
        setTrigger(false);
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        setPreview(null);
    };

    const handleHeightChange = (e) => {
        const newHeight = e.target.value;
        updateHeight(newHeight);
        setHeight(newHeight);
    };

    const handleWidthChange = (e) => {
        const newWidth = e.target.value;
        updateWidth(newWidth);
        setWidth(newWidth);
    };
    
    return (
        <Segment>
            <ContentWrapper>
                {preview && (
                    <Button floated="right" icon="remove" onClick={handleRemoveImage} />
                )}
                <Title>Upload Company Logo</Title>
                <Description>Click the image or Drag & Drop the Image here</Description>
                <Dropzone onDrop={handleDrop} maxFiles={1} accept="image/jpeg,image/png,image/gif">
                    {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()}>
                            {preview ? (
                                <InnerBox>
                                    <StyledImage src={preview} alt="Preview" height={height} width={width} />
                                </InnerBox>
                            ) : (
                                <Placeholder>
                                    <Icon name="upload" />
                                    Drag 'n' drop Image files here, or click to select files
                                </Placeholder>
                            )}
                            <input {...getInputProps()} />
                        </Box>
                    )}
                </Dropzone>
                <Label>Set Logo Height:</Label>
                <Input
                    type="number"
                    value={height}
                    onChange={handleHeightChange}
                    placeholder="Height in pixels"
                />
                <Label>Set Logo Width:</Label>
                <Input
                    type="number"
                    value={width}
                    onChange={handleWidthChange}
                    placeholder="Width in pixels"
                />
            </ContentWrapper>
        </Segment>
    );
};
export default ImageUpload;
// Styled components
const StyledImage = styled.img`
    object-fit: contain;
    display: block;
    margin: auto;
`;
const Title = styled.h3`
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
`;
const Description = styled.p`
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
`;
const Box = styled.div`
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
`;
const InnerBox = styled.div`
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 15px;
    background-color: #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Placeholder = styled.p`
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
    text-align: center;
`;
const ContentWrapper = styled.div`
    padding: 30px;
`;
const Label = styled.label`
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
    display: block;
    margin-bottom: 5px;
`;
const Input = styled.input`
    width: 100px;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Red Hat Display', sans-serif;
`;